import Bugsnag from '@bugsnag/js'

Bugsnag.start({
  apiKey: '6986f5ca215d0d5df21639934b571a59',
  releaseStage: process.env.RAILS_ENV,
  enabledReleaseStages: ['production', 'staging'],

  // Update for each new release
  appVersion: '12.65.0'
})
